import React, { useContext } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './components/Dashboard';
import LoaderImg from './images/loader.svg';
import { Context } from "./context/context";
import Transcations from './components/Transcations';
import Profile from './components/Profile';
import ScrollToTop from "./components/ScrollToTop";
import LoadingBar from 'react-top-loading-bar'
import SwapFrame from './components/SwapFrame';
import IdeaSubmission from './components/IdeaSubmission';

function App() {
  const { darkMode , setProgress , progress } = useContext(Context);
  return (
    <div className="App">
      <div className={`horizontalMenucontainer  ${darkMode ? 'dark-theme' : 'light-theme'}`}>
        <div id="global-loader" style={{ "display": "none" }}>
          <img src={LoaderImg} className="loader-img" alt="Loader" />
        </div>
        <div className="page">
          <LoadingBar
            color='#f11946'
            progress={progress}
            height={4}
            onLoaderFinished={() => setProgress(0)}
          />
          <Router>
            <ScrollToTop>
              <ToastContainer pauseOnFocusLoss={false} />
              <Header />
              <Switch>

                <Route exact path="/">
                  <Dashboard />
                </Route>
                <Route exact path="/transcations">
                  <Transcations />
                </Route>
                <Route exact path="/account">
                  <Profile />
                </Route>
                <Route exact path="/swap">
                  <SwapFrame />
                </Route>
                <Route exact path="/idea-submission">
                  <IdeaSubmission />
                </Route>
              </Switch>
              <Footer />
            </ScrollToTop>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;

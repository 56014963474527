import React, { useState, useEffect, useContext } from 'react';
import workImg from '../images/work3.png';
import { toast } from 'react-toastify';
import { useAccountStats } from '../hooks/useAccount';
import { formatPrice } from '../hooks/contractHelper';
import { afterDate, api_key, contract, dateFormat, DEFAULT_CHAIN, formatDateWithZone, networkQuery, userTransactionQuery } from '../hooks/constant';
import dividendAbi from '../json/dividend.json';
import { getWeb3 } from '../hooks/connectors';
import { getContract } from '../hooks/contractHelper';
import { trimAddress } from './Transcations';
import ClipLoader from "react-spinners/ClipLoader";
import { Context } from "../context/context";
import { ethers } from 'ethers';
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from '../hooks/useEthersProvider';


export default function Profile() {
    const { address } = useAccount()
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const { setProgress } = useContext(Context);
    const [updater, setUpdater] = useState(1);
    const accStats = useAccountStats(updater);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [dexData, setDexData] = useState([]);
    const [ensName, setEnsname] = useState('');


    useEffect(() => {
        async function fetch() {
          const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/2b819420936a42b08167b135cb2ba003")
          if (address) {
            let ensName = await provider.lookupAddress(address);
            setEnsname(ensName);
          }
        }
        fetch()
      }, [address])

    const handleClaimReward = async () => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {

                    setLoading(true);
                    let dividendContract = getContract(dividendAbi, contract[DEFAULT_CHAIN].DIVIDEND_ADDRESS, signer);

                    let tx = await dividendContract.claim({ 'from': address });
                    toast.loading('Waiting for confirmation...');
                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('Your last transaction is success!!');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                            }
                        }
                    }, 5000);


                }
                catch (err) {
                    toast.error(err.reason);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Ethereum Mainnet !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const override = {
        display: "block",
        position: "absolute",
        top: "60%",
        left: "50%"
    };

    const variables = {
        "limit": 100,
        "offset": 0,
        "network": networkQuery,
        "token": contract[DEFAULT_CHAIN].GLDN_ADDRESS,
        "after": afterDate,
        "dateFormat": dateFormat,
        "user": address ? address : ''
    }

    useEffect(() => {
        setProgress(60);
        if (address) {
            try {
                var config = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": api_key
                    },
                    body: JSON.stringify({
                        'query': userTransactionQuery,
                        'variables': variables
                    })
                };

                fetch('https://graphql.bitquery.io', config)
                    .then(response => response.json())
                    .then(response => {
                        if (response.data) {
                            setDexData(response.data.ethereum.dexTrades);
                            setLoading1(false);

                        }
                        else {
                            console.log(response);
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                setProgress(100);
            }
            catch (err) {
                console.log(err.message);
                setProgress(100);
            }
        }
        setProgress(100);
        // eslint-disable-next-line
    }, [address])

    return (
        <React.Fragment>
            <div className="jumps-prevent" style={{ "paddingTop": "63.998px" }}></div>
            <div className="main-content side-content pt-0">
                <div className="main-container container-fluid">
                    <div className="inner-body">
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Welcome To Account</h2>
                            </div>
                        </div>
                        <div className="row row-sm">
                            <div className="col-sm-12 col-lg-12 col-xl-12">
                                <div className="row row-sm  mt-lg-4">
                                    <div className="col-sm-12 col-lg-12 col-xl-12">
                                        <div className="card bg-primary custom-card card-box">
                                            <div className="card-body p-4">
                                                <div className="row align-items-center">
                                                    <div className="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12">
                                                        <h4 className="d-flex  mb-3"> <span className="font-weight-bold address-text text-white ">Welcome , {ensName ? ensName : address ? address : 'connect your wallet'} </span> </h4>
                                                        <p className="text-white mb-1"><b className="text-white">Note: </b>Make sure you always connected to secured <b className="text-white">app.eag.network</b> for your wallet safety. </p>
                                                    </div>
                                                    <img src={workImg} alt="user-img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-sm">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="card custom-card card-border">
                                            <div className="card-body">
                                                <div className="card-item">
                                                    <div className="card-item-icon card-icon">
                                                        <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                                                            <g>
                                                                <rect height="14" opacity=".3" width="14" x="5" y="5"></rect>
                                                                <g>
                                                                    <rect fill="none" height="24" width="24"></rect>
                                                                    <g>
                                                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"></path>
                                                                        <rect height="5" width="2" x="7" y="12"></rect>
                                                                        <rect height="10" width="2" x="15" y="7"></rect>
                                                                        <rect height="3" width="2" x="11" y="14"></rect>
                                                                        <rect height="2" width="2" x="11" y="10"></rect>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="card-item-title mb-2">
                                                        <label className="main-content-label tx-13 font-weight-bold mb-1">Your Balance</label>
                                                    </div>
                                                    <div className="card-item-body">
                                                        <div className="card-item-stat">
                                                            <h5 className="font-weight-bold">{accStats.token_balance ? formatPrice(accStats.token_balance) : 0} EAG</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="card custom-card card-border">
                                            <div className="card-body">
                                                <div className="card-item">
                                                    <div className="card-item-icon card-icon">
                                                        <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                                                            <g>
                                                                <rect height="14" opacity=".3" width="14" x="5" y="5"></rect>
                                                                <g>
                                                                    <rect fill="none" height="24" width="24"></rect>
                                                                    <g>
                                                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"></path>
                                                                        <rect height="5" width="2" x="7" y="12"></rect>
                                                                        <rect height="10" width="2" x="15" y="7"></rect>
                                                                        <rect height="3" width="2" x="11" y="14"></rect>
                                                                        <rect height="2" width="2" x="11" y="10"></rect>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="card-item-title mb-2">
                                                        <label className="main-content-label tx-13 font-weight-bold mb-1">Holding Worth</label>
                                                    </div>
                                                    <div className="card-item-body">
                                                        <div className="card-item-stat">
                                                            <h5 className="font-weight-bold">${accStats.token_balance ? formatPrice(accStats.token_balance * accStats.gldn_price) : 0} USD</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-12">
                                        <div className="card custom-card card-border">
                                            <div className="card-body">
                                                <div className="card-item">
                                                    <div className="card-item-icon" style={{ "width": "fit-content" }}>
                                                        <button onClick={() => handleClaimReward()} className='btn ripple btn-primary btn-rounded' disabled={loading}>
                                                            {loading ? 'Claiming...' : 'Claim your Reward'}
                                                        </button>
                                                    </div>
                                                    <div className="card-item-title mb-2">
                                                        <label className="main-content-label tx-13 font-weight-bold mb-1">Your Reward (PAXG)</label>
                                                    </div>
                                                    <div className="card-item-body">
                                                        <div className="card-item-stat">
                                                            <h5 className="font-weight-bold">{formatPrice(accStats.getUnpaidEarning)} PAXG</h5>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="card custom-card card-border">
                                            <div className="card-body">
                                                <div className="card-item">
                                                    <div className="card-item-icon card-icon">
                                                        <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                                                            <g>
                                                                <rect height="14" opacity=".3" width="14" x="5" y="5"></rect>
                                                                <g>
                                                                    <rect fill="none" height="24" width="24"></rect>
                                                                    <g>
                                                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"></path>
                                                                        <rect height="5" width="2" x="7" y="12"></rect>
                                                                        <rect height="10" width="2" x="15" y="7"></rect>
                                                                        <rect height="3" width="2" x="11" y="14"></rect>
                                                                        <rect height="2" width="2" x="11" y="10"></rect>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="card-item-title mb-2">
                                                        <label className="main-content-label tx-13 font-weight-bold mb-1">Your Reward Worth:</label>
                                                    </div>
                                                    <div className="card-item-body">
                                                        <div className="card-item-stat">
                                                            <h5 className="font-weight-bold">${accStats.getUnpaidEarning ? formatPrice(parseFloat(accStats.getUnpaidEarning) * accStats.paxg_price) : 0} USD</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="card custom-card card-border">
                                            <div className="card-body">
                                                <div className="card-item">
                                                    <div className="card-item-icon card-icon">
                                                        <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                                                            <g>
                                                                <rect height="14" opacity=".3" width="14" x="5" y="5"></rect>
                                                                <g>
                                                                    <rect fill="none" height="24" width="24"></rect>
                                                                    <g>
                                                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"></path>
                                                                        <rect height="5" width="2" x="7" y="12"></rect>
                                                                        <rect height="10" width="2" x="15" y="7"></rect>
                                                                        <rect height="3" width="2" x="11" y="14"></rect>
                                                                        <rect height="2" width="2" x="11" y="10"></rect>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="card-item-title mb-2">
                                                        <label className="main-content-label tx-13 font-weight-bold mb-1">Total PAXG Realised</label>
                                                    </div>
                                                    <div className="card-item-body">
                                                        <div className="card-item-stat">
                                                            <h5 className="font-weight-bold">{formatPrice((parseFloat(accStats.realised) - parseFloat(accStats.reserved)) )} PAXG</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="row row-sm">
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                <div className="card custom-card transcation-crypto">
                                    <div className="card-header border-bottom-0">
                                        <div className="main-content-label">Your Transaction History</div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap text-md-nowrap  table-bordered">
                                                <thead>
                                                    <tr role="row">
                                                        <th>Transaction Hash</th>
                                                        <th>Timestamp</th>
                                                        <th>Action</th>
                                                        <th>Price (USD)</th>
                                                        <th>Token Amount</th>
                                                        <th>Total Value (USD)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!loading1 && dexData && dexData.length > 0 ? (dexData.map((rowdata, index) => {
                                                        return (
                                                            <tr className="border-bottom odd" key={index}>
                                                                <td>
                                                                    <a target="_balnk" href={`https://etherscan.io/tx/${rowdata.transaction.hash ? rowdata.transaction.hash : ''}`}>
                                                                        {trimAddress(rowdata.transaction.hash ? rowdata.transaction.hash : '')}
                                                                    </a>
                                                                </td>
                                                                <td>{rowdata.block.timestamp.time ? formatDateWithZone(rowdata.block.timestamp.time) : ' - '}</td>
                                                                <td>{rowdata.buyCurrency.symbol ? rowdata.buyCurrency.symbol === 'EAG' ? <>SELL <i className="fas fa-level-down-alt ms-2 text-danger m-l-10"></i></> : <>BUY <i className="fas fa-level-up-alt ms-2 text-success m-l-10"></i></> : '-'}</td>
                                                                <td>
                                                                    ${rowdata.buyAmount && rowdata.buyCurrency.symbol ? (rowdata.buyCurrency.symbol === 'EAG' ? formatPrice(parseFloat(rowdata.quote_amount_usd) / parseFloat(rowdata.buyAmount)) : formatPrice(parseFloat(rowdata.quote_amount_usd) / parseFloat(rowdata.sellAmount))) : 0}
                                                                </td>
                                                                <td>
                                                                    {rowdata.buyCurrency.symbol ? rowdata.buyCurrency.symbol === 'EAG' ? parseFloat(rowdata.buyAmount).toFixed(3) : parseFloat(rowdata.sellAmount).toFixed(3) : '-'} EAG
                                                                </td>
                                                                <td>
                                                                    ${rowdata.buyAmount && rowdata.buyCurrency.symbol ? (rowdata.buyCurrency.symbol === 'EAG' ? formatPrice(parseFloat(rowdata.quote_amount_usd)) : formatPrice(parseFloat(rowdata.quote_amount_usd))) : 0}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })) :
                                                        (
                                                            <tr className="border-bottom odd">
                                                                <td colSpan={6} className="text-center">
                                                                    No record Found
                                                                </td>

                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        loading1 &&
                                                        <tr className="text-center">
                                                            <td colspan="6" height={300} >
                                                                <ClipLoader
                                                                    color="#000"
                                                                    loading={true}
                                                                    cssOverride={override}
                                                                    size={50}
                                                                // className="spinner"
                                                                />
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

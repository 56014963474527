import { ethers } from "ethers";
import { getWeb3 } from "./connectors";
import { DEFAULT_CHAIN, contract } from "./constant";
import multicallAbi from '../json/multicall.json';



export const getContract = (abi, address, library) => {
  try{
    return new ethers.Contract(address, abi, library)
  }
  catch{
    return false;
  }
}


export const getMultiCall = async (calls = []) => {
  let web3 = getWeb3();
  let multicallAddress = contract[DEFAULT_CHAIN].MULTICALL_ADDRESS;
  const mc = new web3.eth.Contract(multicallAbi, multicallAddress);
  const callRequests = calls.map((call) => {
    const callData = call.encodeABI();
    return {
      target: call._parent._address,
      callData,
    };
  });

  const { returnData } = await mc.methods
    .aggregate(callRequests)
    .call({});

  let finalData = returnData.map((hex, index) => {
    const types = calls[index]._method.outputs.map((o) =>
      o.internalType !== o.type && o.internalType !== undefined ? o : o.type
    );

    let result = web3.eth.abi.decodeParameters(types, hex);

    delete result.__length__;

    result = Object.values(result);

    return result.length === 1 ? result[0] : result;
  });

  return finalData;
}




export const formatPrice = (num) => {
  //return parseFloat(num.toFixed(decimals)).toLocaleString();
  try{
    if(num < 1){
      return new Intl.NumberFormat('en-US' , {maximumFractionDigits : 5}).format(num);
    }
    else{
      return new Intl.NumberFormat('en-US' , {maximumFractionDigits : 3}).format(num);
    }
    
  }
  catch(err){
    return 0;
  }
}


export const getWeb3Contract = (abi, address) => {
  let web3 = getWeb3();
  return new web3.eth.Contract(abi, address);
}



import { useEffect, useState  } from "react"
import { contract, DEFAULT_CHAIN } from "./constant";
import { toast } from "react-toastify";
import { getMultiCall } from "./contractHelper";
import tokenAbi from '../json/token.json';
import dividendAbi from '../json/dividend.json';
import PairContractAbi from '../json/PairContract.json';
import linkAbi from '../json/chainlink.json';
import { getWeb3 } from "./connectors";

export const useTokenInfoStats = (updater) => {
  let web3 = getWeb3();
  const [stats, setStats] = useState({
    decimal: 0,
    totalSuppl: 0,
    getCirculatingSupply: 0,
    eth_price: 0,
    gldn_price: 0,
    paxg_price: 0,
    totalDistributed: 0,
    totalReserved: 0,
    liquidity: 0,
    totalBuyTax: 3,
    totalSellTax: 3
  });

 
  const tokenContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].GLDN_ADDRESS);
  const pairContract = new web3.eth.Contract(PairContractAbi, contract[DEFAULT_CHAIN].GLDN_LP_ADDRESS);
  const ethLinkContract = new web3.eth.Contract(linkAbi, contract[DEFAULT_CHAIN].ETH_LINK_ADDRESS);
  const dividendContract = new web3.eth.Contract(dividendAbi, contract[DEFAULT_CHAIN].DIVIDEND_ADDRESS);
  const paxgPairContract = new web3.eth.Contract(PairContractAbi, contract[DEFAULT_CHAIN].PAXG_LP_ADDRESS);



  useEffect(() => {
    const fetch = async () => {
      try {

        const data = await getMultiCall([
          tokenContract.methods.decimals(), //0
          tokenContract.methods.totalSupply(), //1
          dividendContract.methods.totalDistributed(), //2
          tokenContract.methods.balanceOf(contract[DEFAULT_CHAIN].GLDN_LP_ADDRESS), //3
          pairContract.methods.getReserves(), //4
          ethLinkContract.methods.latestRoundData(), //5
          paxgPairContract.methods.getReserves(), //6
          dividendContract.methods.totalReserved() //7
        ])



        
        let eth_price = parseFloat(data[5][1] / Math.pow(10,8));
        let gldn_price =  eth_price * (data[4][1] / data[4][0])
        let paxg_price = eth_price * (data[6][1] / data[6][0])

      
        setStats({
          decimal: data[0],
          totalSuppl: data[1] / Math.pow(10,data[0]),
          getCirculatingSupply: 0,
          eth_price: eth_price,
          gldn_price: gldn_price,
          paxg_price: paxg_price,
          totalDistributed: parseFloat(data[2] / Math.pow(10,data[0])) * paxg_price,
          liquidity: parseFloat(data[3]/Math.pow(10,data[0])) * 2 * gldn_price,
          totalBuyTax: 3,
          totalSellTax: 3,
          totalReserved: parseFloat(data[7] / Math.pow(10,data[0])) * paxg_price
        })
        
      }
      catch (err) {
        console.log(err.message);
        toast.error(err.reason);
        

      }
      
    }

    
    fetch();
    
    
    // eslint-disable-next-line
  }, [updater]);

  return stats;
}

import { useEffect, useState } from "react"
import { contract, DEFAULT_CHAIN } from "./constant";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { getMultiCall } from "./contractHelper";
import tokenAbi from '../json/token.json';
import dividendAbi from '../json/dividend.json';
import PairContractAbi from '../json/PairContract.json';
import linkAbi from '../json/chainlink.json';
import { getWeb3 } from "./connectors";


export const useAccountStats = (updater) => {
    let web3 = getWeb3();
    let { address } = useAccount();

    const [stats, setStats] = useState({
        token_balance: 0,
        eth_price: 0,
        gldn_price: 0,
        paxg_price: 0,
        reward_balance: 0, 
        totalExcluded: 0,
        realised: 0,
        reserved: 0,
        getUnpaidEarning : 0

    });

    const tokenContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].GLDN_ADDRESS);
    const pairContract = new web3.eth.Contract(PairContractAbi, contract[DEFAULT_CHAIN].GLDN_LP_ADDRESS);
    const ethLinkContract = new web3.eth.Contract(linkAbi, contract[DEFAULT_CHAIN].ETH_LINK_ADDRESS);
    const dividendContract = new web3.eth.Contract(dividendAbi, contract[DEFAULT_CHAIN].DIVIDEND_ADDRESS);
    const paxgPairContract = new web3.eth.Contract(PairContractAbi, contract[DEFAULT_CHAIN].PAXG_LP_ADDRESS);


    useEffect(() => {
        const fetch = async () => {
            try {

                const data = await getMultiCall([
                    tokenContract.methods.balanceOf(address), //0
                    tokenContract.methods.balanceOf(contract[DEFAULT_CHAIN].GLDN_LP_ADDRESS), //1
                    pairContract.methods.getReserves(), //2
                    ethLinkContract.methods.latestRoundData(), //3
                    paxgPairContract.methods.getReserves(), //4
                    dividendContract.methods.shares(address), //5
                    dividendContract.methods.getUnpaidEarning(address)
                  ])

                let eth_price = parseFloat(data[3][1] / Math.pow(10,8));
                let gldn_price =  eth_price * (data[2][1] / data[2][0])
                let paxg_price =  eth_price * (data[4][1] / data[4][0]);


               setStats({
                    token_balance: data[0] / Math.pow(10,18),
                    eth_price: eth_price,
                    gldn_price: gldn_price,
                    paxg_price: paxg_price,
                    reward_balance: data[5][0] / Math.pow(10,18), 
                    totalExcluded: data[5][1] / Math.pow(10,18),
                    realised: data[5][2] / Math.pow(10,18),
                    reserved: data[5][3] / Math.pow(10,18),
                    getUnpaidEarning : data[6] / Math.pow(10,18)
                    
                
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)

            }
        }

        if (address) {
            fetch();
        }
        else {
            setStats({
                token_balance: 0,
                eth_price: 0,
                gldn_price: 0,
                paxg_price: 0,
                reward_balance: 0, 
                totalExcluded: 0,
                realised: 0,
                reserved: 0,
                getUnpaidEarning : 0
            })
        }
        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}

export const useIdeaStats = (updater) => {
    let web3 = getWeb3();
    let { address } = useAccount();
    

    const [stats, setStats] = useState({
        token_balance: 0,
        loading : true
    });

    const tokenContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].GLDN_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {

                const data = await getMultiCall([
                    tokenContract.methods.balanceOf(address) //0
                  ])

               setStats({
                    token_balance: data[0] / Math.pow(10,18),
                    loading : false
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address) {
            fetch();
        }
        else {
            setStats({
                token_balance: 0,
                loading : true
            })
        }
        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}

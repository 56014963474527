export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  1: {
    MULTICALL_ADDRESS: "0x5e227ad1969ea493b43f840cff78d08a6fc17796",
    WETH_ADDRESS: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    GLDN_ADDRESS: "0xa338b5a4bbd8053994bb6c55d770fc2447d66b88",
    GLDN_LP_ADDRESS: "0x7f00995f977a8a64abd4b888e5cf09d86f91ca66",
    WETH_USD_LP_ADDRESS: "0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852",
    DIVIDEND_ADDRESS: "0xC25CA21766E9b2d83709e7fAB7b6bCa370D298A3",
    PAXG_ADDRESS:"0x45804880De22913dAFE09f4980848ECE6EcbAf78",
    PAXG_LP_ADDRESS:"0x9c4fe5ffd9a9fc5678cfbd93aa2d4fd684b67c4c",
    USDT_USDC_LP_ADDRESS : "0x3041CbD36888bECc7bbCBc0045E3B1f144466f5f",
    ETH_LINK_ADDRESS : "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
    PAXG_LINK_ADDRESS : "0x9B97304EA12EFed0FAd976FBeCAad46016bf269e",
    PAGX_RESERVE_ADDR : "0x716BB8c60D409e54b8Fb5C4f6aBC50E794DA048a",

  }
}

export const DEFAULT_CHAIN = 1;
export const networkQuery = 'ethereum';
export const afterDate = '2022-10-03';
export const dateFormat = "%Y-%m-%d";
export const api_key = 'BQYNgfV1Ejbcx3IfcHPcJITsi6Omi6OH';
export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const MIN_BAL_FORM = 1;



export const transactionQuery = `query ($network: EthereumNetwork!, $token: String!, $limit: Int!, $offset: Int!,$after: ISO8601DateTime) {
  ethereum(network: $network) {
    dexTrades(
      options: {desc: ["block.height", "tradeIndex"], limit: $limit, offset: $offset}
      date: {after: $after}
      baseCurrency: {is: $token}
    ) {
      block {
        timestamp {
          time(format: "%Y-%m-%d %H:%M:%S")
        }
        height
      }
      tradeIndex
      protocol
      exchange {
        fullName
      }
      smartContract {
        address {
          address
          annotation
        }
      }
      buyAmount
      buyCurrency {
          address
          symbol
      }
      
      base_amount_usd: baseAmount(in: USD)
      sellAmount
      sellCurrency {
          address
          symbol
      }
      quote_amount_usd: quoteAmount(in: USD)
      transaction {
        hash
      }
    }
  }
}`;


export const userTransactionQuery = `query ($network: EthereumNetwork!,  $user: String!  , $token: String!, $limit: Int!, $offset: Int!, $after: ISO8601DateTime) {
  ethereum(network: $network) {
    dexTrades(
      options: {desc: ["block.height", "tradeIndex"], limit: $limit, offset: $offset}
      date: {after: $after}
      baseCurrency: {is: $token}
    ) {
      block {
        timestamp {
          time(format: "%Y-%m-%d %H:%M:%S")
        }
        height
      }
      tradeIndex
      protocol
      exchange {
        fullName
      }
      smartContract {
        address {
          address
          annotation
        }
      }
      buyAmount
      buyCurrency {
          address
          symbol
      }
      
      base_amount_usd: baseAmount(in: USD)
      sellAmount
      sellCurrency {
          address
          symbol
      }
      quote_amount_usd: quoteAmount(in: USD)
      quoteCurrency(
        quoteCurrency: {in: ["0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", "0xFeeB4D0f5463B1b04351823C246bdB84c4320CC2"]}
      ) {
        address
        symbol
      }
      transaction(txSender: {is: $user}) {
        hash
      }
      maker {
        address
      }
    }
  }
}
`

export const formatDateWithZone = (unixTime) =>{
  try{
    let dateString = new Date(`${unixTime} UTC`).toString();
    let startIndex = dateString.indexOf("GMT");
    let modifyDate = dateString.substring(0,startIndex);
    return modifyDate;
  }
  catch(err){
    console.log(err.message);
  }
  
}


export const chartQuery = `{
  ethereum(network: ethereum) {
    dexTrades(
      options: {asc: "timeInterval.minute"}
      date: {since: "2022-11-27"}
      exchangeName: {is: "Uniswap"}
      baseCurrency: {is: "0xfeeb4d0f5463b1b04351823c246bdb84c4320cc2"}
      quoteCurrency: {is: "0x590619f44e1550cccd6f7778ae73947050aec22b"}
    ) {
      timeInterval {
        minute(count: 30)
      }
      high: quotePrice(calculate: maximum)
      low: quotePrice(calculate: minimum)
      open: minimum(of: block, get: quote_price)
      close: maximum(of: block, get: quote_price)
      baseCurrency {
        name
      }
      quoteCurrency {
        name
      }
    }
  }
}`;


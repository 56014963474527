import React, { useContext, useEffect, useRef } from 'react'
import { useIdeaStats } from '../hooks/useAccount'
import { useAccount } from 'wagmi';
import { MIN_BAL_FORM } from '../hooks/constant';


import { Context } from '../context/context';


export default function IdeaSubmission() {
  const stats = useIdeaStats(1);
  const { address, isConnected } = useAccount();

  const { setProgress } = useContext(Context);


  useEffect(() => {

    setProgress(100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="jumps-prevent" style={{ "paddingTop": "63.998px" }}></div>
      {!address || !isConnected ? (
        <div className='coinatiner' style={{ "marginTop": "15%" }}>
          <div className='d-flex justify-content-center' style={{fontSize : "22px"}}>
            
              Please Connect Wallet
            

          </div>
        </div>
      ) : (parseFloat(stats.token_balance) >= parseFloat(MIN_BAL_FORM) ? (
        <>

          <div className="main-content side-content pt-0">
            <iframe title='uniswap Frame' src={`https://docs.google.com/forms/d/e/1FAIpQLScds54QJvwYkiAu9Qb4ArWOiUYG7sjQOAKTNd-99G3xwR_c_g/viewform?embedded=true`} height="600px" width="100%"></iframe>
          </div>
        </>
      ) : (
        <div className='coinatiner' style={{ "marginTop": "15%" }}>
          <div className='d-flex justify-content-center' style={{fontSize : "22px"}}>
            
              You don't have enough EAG. please buy some EAG to get access.
            
          </div>
        </div>
      ))}

    </>

  )
}
